export default function ({$axios, redirect, $modalityStore, route}) {
    $axios.onError(err => {
        if (err.response && err.response.status == 401) {
            if (/^login___[a-z]{2}$/g.test(route.name)) {
                return;
            }

            let payload = {type: 'drawer', name: 'login'};

            if (err.response.data && err.response.data.message) {
                payload.message = err.response.data.message;
            }

            $modalityStore.openModal(payload);
        }

        if (err.response && err.response.status == 500) {
            // Dont redirect on post requests
            if (!err.response.data || err.response.data.config.method != 'post') {
                redirect('/sorry')
            }
        }
    });
}
