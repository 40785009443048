import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
export var useModalityStore = defineStore({
  id: 'modality',
  // initial state
  state: function state() {
    return {
      test: false,
      dropdowns: [],
      gain: null,
      drawer: null,
      // hapticMenu: null,
      cart: null,
      drawerMessage: null,
      overlayChanged: false,
      overlay: null,
      menu: null,
      logs: [],
      hapticTimeout: null,
      lockHapticTimeout: false
    };
  },
  getters: {
    tintShown: function tintShown(state) {
      var types = ['drawer', 'gain', 'cart'];
      return state.logs.some(function (o) {
        return types.includes(o.type) && !o.noTint;
      });
    }
  },
  actions: {
    // setLockHapticTimeout (payload) {
    //     this.lockHapticTimeout = payload;
    // },
    // clearHapticTimeoutClose () {
    //     if (this.hapticTimeout) clearTimeout(this.hapticTimeout);
    // },
    change: function change(type) {
      this["".concat(type, "Changed")] = true;
    },
    save: function save(type) {
      this["".concat(type, "Changed")] = false;
    },
    openModal: function openModal(payload) {
      this[payload.type] = payload.name;
      this["".concat(payload.type, "Changed")] = false;
      if (payload.message) {
        this["".concat(payload.type, "Message")] = payload.message;
      } else {
        this["".concat(payload.type, "Message")] = null;
      }
      this.logs.unshift({
        noTint: payload.noTint,
        type: payload.type,
        name: payload.name,
        time: new Date()
      });
    },
    closeModal: function closeModal(payload) {
      // Validate type of modality
      if (!this[payload.type]) return true;

      // Confirn closing modal with non saved form
      if (this["".concat(payload.type, "Changed")] && !confirm(this.$nuxt.i18n.t('confirm.modalNotSaved'))) {
        return true;
      }

      // Clear form saved state
      this["".concat(payload.type, "Changed")] = false;

      // Clear modality type
      this[payload.type] = null;

      // Clear logs of this type of modality
      var index = 0;
      do {
        index = this.logs.findIndex(function (o) {
          return o.type == payload.type;
        });
        if (index >= 0) this.logs.splice(index, 1);
      } while (index != -1);
    },
    openDropdown: function openDropdown(payload) {
      var result = this.dropdowns.find(function (o) {
        return o.name == payload.name;
      });
      if (result) return;
      this.dropdowns.unshift(payload);
      this.logs.unshift({
        type: 'dropdown',
        name: payload.name,
        time: new Date()
      });
    },
    closeDropdown: function closeDropdown(payload) {
      var index = this.dropdowns.findIndex(function (o) {
        return o.name == payload.name;
      });
      if (index >= 0) this.dropdowns.splice(index, 1);
      var logIndex = this.logs.findIndex(function (o) {
        return o.type == 'dropdown' && o.name == payload.name;
      });
      if (logIndex >= 0) this.logs.splice(logIndex, 1);
    },
    closeDropdowns: function closeDropdowns() {
      this.dropdowns = [];
    },
    closeAllModals: function closeAllModals() {
      this.drawer = null;
      this.gain = null;
      this.overlay = null;
      this.menu = null;
      this.cart = null;
      // this.hapticMenu = null;
      this.dropdowns = [];
      this.logs = [];
    },
    // hapticTimeoutClose () {
    //     if (!this.hapticMenu) return;
    //     this.hapticTimeout = setTimeout(() => {
    //         if (this.lockHapticTimeout) return true;
    //         this.closeModal({type: 'hapticMenu'});
    //     }, 600)
    // },
    closeLast: function closeLast() {
      var log = this.logs[0];
      if (!log) return true;
      if (log.type == 'dropdown') this.closeDropdown({
        name: log.name
      });else this.closeModal({
        type: log.type
      });
    },
    toggleModal: function toggleModal(payload) {
      // Just open modal
      if (!this[payload.type]) return this.openModal(payload);

      // Close
      if (this[payload.type] === payload.name) return this.closeModal(payload);

      // Switch modal
      this.closeModal(payload);
      this.openModal(payload);
    },
    toggleDropdown: function toggleDropdown(payload) {
      var index = this.dropdowns.findIndex(function (o) {
        return o.name == payload.name;
      });
      if (index >= 0) this.closeDropdown(payload);else this.openDropdown(payload);
    }
  }
});