import Vue from 'vue';

// const isOverflowHidden = () => {
//     const bodyStyle = window.getComputedStyle(document.body);
//     return bodyStyle.overflow === 'hidden';
// };

const cropContent = function () {
    window.requestAnimationFrame(() => {
        let root = document.documentElement;
        let scrollY = window.scrollY;

        root.style.setProperty('--scroll-y', scrollY + 'px');

        let documentHeight = root.scrollHeight;
        let bottomPadding = documentHeight - (scrollY + window.innerHeight);
        bottomPadding = bottomPadding < 0 ? 0 : bottomPadding;

        root.style.setProperty('--bottom-padding', bottomPadding + 'px');

        // if (!isOverflowHidden()) setTimeout(cropContent, 50);
    })
}


Vue.prototype.$cropContent = cropContent;
