import Vue from 'vue';

const link = {
    protocol (url, scheme = 'https') {
        if (url.match(/^[a-zA-Z]+:\/\//)) {
            return url;
        }

        return scheme + '://' + url;
    },

    phone (phone) {
        return 'tel:+' + phone.replace(/[^\d]/g, '');
    },

    email (email) {
        return `mailto:${email}`;
    }
}


Vue.prototype.$link = link;
