/**
 * A composable function to copy a string value to the clipboard and display a toast notification.
 * 
 * This function first tries to copy the text using the modern Clipboard API. If the Clipboard API is
 * unavailable, it falls back to using the deprecated `document.execCommand('copy')` method.
 * 
 * @function
 * @param {string} value - The text value to be copied to the clipboard.
 * @param {string} [toastText] - Optional. The text for the toast notification. If not provided, the default text from the i18n translation will be used.
 * 
 * @example
 * useCopyToClipboard('Hello World', 'Copied to clipboard!');
 * 
 * @remarks
 * - The function will display a success toast message after copying the text.
 * - It uses Nuxt's `useNuxtApp()` to access `$i18n` and `$toastStore`.
 * - The fallback method `document.execCommand('copy')` is deprecated and should only be used if the Clipboard API is not available.
 * 
 * @returns {void} This function does not return any value.
 */

export function useCopyToClipboard(value, toastText) {
  var _useNuxtApp = useNuxtApp(),
    $i18n = _useNuxtApp.$i18n,
    $toastStore = _useNuxtApp.$toastStore;

  // Check if the Clipboard API is available
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(value).then(function () {
      $toastStore.add({
        title: toastText || $i18n.t('toast.copied'),
        closable: true,
        icon: 'icon-ic-copy'
      });
    }).catch(function (err) {
      console.error('Failed to copy text: ', err);
    });
  } else {
    // Fallback for older browsers
    var inp = document.createElement('input');
    document.body.appendChild(inp);
    inp.value = value;
    inp.select();
    document.execCommand('copy');
    document.body.removeChild(inp);
    $toastStore.add({
      title: toastText || $i18n.t('toast.copied'),
      closable: true,
      icon: 'icon-ic-copy'
    });
  }
}