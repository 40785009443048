export var useCinemaStore = defineStore({
  id: 'cinema',
  state: function state() {
    return {
      opened: false,
      playlist: [],
      current: null,
      currentIndex: 0
    };
  },
  actions: {
    play: function play(index) {
      if (!this.playlist.length) return this.current = null;
      var currentIndex = index && this.playlist[index] ? index : 0;
      this.currentIndex = currentIndex;
      this.current = this.playlist[currentIndex];
    },
    changeVideo: function changeVideo(index) {
      this.currentIndex = index;
    },
    on: function on(_ref) {
      var playlist = _ref.playlist,
        index = _ref.index;
      if (!playlist.length) return true;
      this.playlist = playlist;
      this.play(index);
      this.opened = true;
    },
    off: function off() {
      this.opened = false;
      this.playlist = [];
      this.play(null);
    }
  }
});