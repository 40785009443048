import "core-js/modules/es.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
var casesStructuredClone = function casesStructuredClone(obj) {
  if (typeof structuredClone !== 'undefined') {
    return structuredClone(obj);
  } else {
    return JSON.parse(JSON.stringify(obj));
  }
};
export default (function (context, inject) {
  inject('structuredClone', casesStructuredClone);
  context.$structuredClone = casesStructuredClone;
});