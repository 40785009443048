export const useVanilaCookie = () => {

    // Remove cookies starting with a given prefix
    const removeCookiesStartingWith = (prefix) => {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();

            if (cookie.indexOf(prefix) === 0) {
                document.cookie = cookie.split('=')[0] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
        }
    }

    return {
        removeCookiesStartingWith
    }
}