var LiqpayStates = {
  liqpayRequest: null,
  action: 'pay',
  subscription: null,
  value: 0
};
export var useLiqpayStore = defineStore({
  id: 'liqpay',
  // initial state
  state: function state() {
    return {
      cart: Object.assign({}, LiqpayStates, {
        action: 'pay'
      }),
      subscription: Object.assign({}, LiqpayStates, {
        action: 'subscribe'
      }),
      loaded: false,
      // Liqpay script is loaded
      ready: false,
      // Liqpay script is ready to run
      error: false // Error occured on liqpay loading
    };
  },
  actions: {
    liqpayLoaded: function liqpayLoaded() {
      this.loaded = true;
    },
    liqpayReady: function liqpayReady() {
      this.ready = true;
    },
    liqpayError: function liqpayError() {
      this.error = true;
    },
    setLiqpayRequest: function setLiqpayRequest(_ref) {
      var request = _ref.request,
        liqpay = _ref.liqpay;
      this[liqpay].liqpayRequest = request;
    },
    setSubscription: function setSubscription(_ref2) {
      var liqpay = _ref2.liqpay,
        slug = _ref2.slug,
        price = _ref2.price;
      this[liqpay].subscription = slug;
      this[liqpay].value = price;
    }
  }
});