import { useToastStore } from '~/stores/toast';
import { useModalityStore } from '~/stores/modality';
import { useCookieStore } from '~/stores/cookie';
import { useAppStore } from '~/stores/app';
export default (function (context, inject) {
  var toastStore = useToastStore(context.$pinia);
  var modalityStore = useModalityStore(context.$pinia);
  var cookieStore = useCookieStore(context.$pinia);
  var appStore = useAppStore(context.$pinia);

  // Make $toastStore global
  inject('toastStore', toastStore);
  context.$toastStore = toastStore;

  // Make $modalityStore global
  inject('modalityStore', modalityStore);
  context.$modalityStore = modalityStore;

  // Make $cookieStore global
  inject('cookieStore', cookieStore);
  context.$cookieStore = cookieStore;

  // Make $appStore global
  inject('appStore', appStore);
  context.$appStore = appStore;
});