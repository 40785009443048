import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
export var useUsersListStore = defineStore({
  id: 'usersList',
  state: function state() {
    return {
      length: 0,
      users: [],
      title: '',
      id: null,
      loading: false,
      params: null,
      url: null,
      searchTerm: ''
    };
  },
  actions: {
    update: function update(payload) {
      var _this = this;
      if (!payload.url) return;
      this.loading = true;
      this.searchTerm = '';
      this.url = payload.url;
      this.params = payload.params;
      this.id = payload.id;
      this.title = payload.title;
      this.users = [];
      this.$nuxt.$modalityStore.openModal({
        type: 'drawer',
        name: 'usersList'
      });
      var params = Object.assign({
        limit: 50
      }, payload.params);
      this.$nuxt.$axios.$get(this.url, {
        params: params,
        progress: false
      }).then(function (data) {
        if (data) {
          _this.users = data.users;
          _this.length = data.length;
        }
        _this.loading = false;
      }).catch(function (err) {
        console.error(err);
        _this.loading = false;
      });
    },
    load: function load() {
      var _this2 = this;
      if (this.loading) return;
      var length = this.length || 0;
      if (length <= this.users.length) return;
      this.loading = true;
      var params = Object.assign({
        skip: this.users.length,
        search: this.search
      }, this.params);
      this.$nuxt.$axios.$get(this.url, {
        params: params,
        progress: false
      }).then(function (data) {
        var _this2$users;
        if (data) (_this2$users = _this2.users).push.apply(_this2$users, _toConsumableArray(data.users));
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    search: function search(_search) {
      var _this3 = this;
      if (this.loading) return;
      this.loading = true;
      this.searchTerm = _search;
      var params = Object.assign({
        skip: 0,
        search: _search
      }, this.params);
      this.$axios.$get(this.url, {
        params: params,
        progress: false
      }).then(function (data) {
        if (data) _this3.users = data.users;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    }
  }
});