export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"dateTimeFormats":{"uk":{"dd mmmm yyyy":{"month":"long","day":"numeric","year":"numeric"},"dd mmmm":{"month":"long","day":"numeric"},"dd mmmm time":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric"},"dd mmmm yyyy time":{"month":"long","day":"numeric","year":"numeric","hour":"numeric","minute":"numeric"},"hh mm":{"hour":"numeric","minute":"numeric"},"short":{"year":"numeric","month":"short","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric","second":"numeric"}},"en":{"dd mmmm yyyy":{"month":"long","day":"numeric","year":"numeric"},"dd mmmm":{"month":"long","day":"numeric"},"dd mmmm time":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric"},"dd mmmm yyyy time":{"month":"long","day":"numeric","year":"numeric","hour":"numeric","minute":"numeric"},"hh mm":{"hour":"numeric","minute":"numeric"},"short":{"year":"numeric","month":"short","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric","second":"numeric"}}}},
  vueI18nLoader: true,
  locales: [{"code":"uk","name":"UK","file":"uk.json","iso":"uk-UA"},{"code":"en","name":"EN","file":"en.json","iso":"en-US"}],
  defaultLocale: "uk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/cases/data/www/client/locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true,"redirectOn":"root"},
  differentDomains: false,
  seo: false,
  baseUrl: "https://cases.media",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: true,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"uk","name":"UK","file":"uk.json","iso":"uk-UA"},{"code":"en","name":"EN","file":"en.json","iso":"en-US"}],
  localeCodes: ["uk","en"],
}

export const localeMessages = {
  'uk.json': () => import('../../client/locales/uk.json' /* webpackChunkName: "lang-uk.json" */),
  'en.json': () => import('../../client/locales/en.json' /* webpackChunkName: "lang-en.json" */),
}
