function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { useLiqpayStore } from '~/stores/liqpay';
var emptyCart = {
  cartState: 'cart',
  id: null,
  company: null,
  expire: null,
  items: [],
  total: {
    amount: 0,
    string: 'Жодного товару'
  }
};
export var useCartStore = defineStore({
  id: 'cart',
  state: function state() {
    return {
      carts: [],
      forceRefresh: false,
      activeCartIndex: 0
    };
  },
  getters: {
    isEmpty: function isEmpty() {
      return !this.itemsLength;
    },
    activeCart: function activeCart(state) {
      return state.carts[state.activeCartIndex] || emptyCart;
    },
    itemsLength: function itemsLength(state) {
      var number = 0;
      state.carts.forEach(function (cart) {
        return number += cart.items ? cart.items.length : 0;
      });
      return number;
    },
    nonEmptyCartsLength: function nonEmptyCartsLength(state) {
      var number = 0;
      var _iterator = _createForOfIteratorHelper(state.carts),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _cart$items;
          var cart = _step.value;
          if (((_cart$items = cart.items) === null || _cart$items === void 0 ? void 0 : _cart$items.length) > 0) number++;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return number;
    },
    nonEmptyCartIndex: function nonEmptyCartIndex(state) {
      return state.carts.findIndex(function (cart) {
        var _cart$items2;
        return ((_cart$items2 = cart.items) === null || _cart$items2 === void 0 ? void 0 : _cart$items2.length) > 0;
      });
    }
  },
  actions: {
    setActiveCartIndex: function setActiveCartIndex() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      if (!this.carts[index]) index = 0;
      this.activeCartIndex = index;
    },
    setCartState: function setCartState(cartState) {
      this.carts[this.activeCartIndex].cartState = cartState;
    },
    updateCart: function updateCart(payload) {
      payload.cartState = 'cart';
      if (payload.expireAt) payload.expireAt = new Date(payload.expireAt);
      var index = -1;
      if (payload.company) {
        index = this.carts.findIndex(function (cart) {
          return cart.company && cart.company.id == payload.company.id;
        });
      } else {
        index = this.carts.findIndex(function (cart) {
          return !cart.company;
        });
      }
      payload.cartState = 'cart';
      if (index >= 0 && this.carts[index].cartState == 'bill-editor') {
        payload.cartState = 'bill-editor';
      }
      if (index < 0) {
        return this.carts.push(payload);
      }
      this.carts.splice(index, 1, payload);
    },
    updateCarts: function updateCarts(payload) {
      var _this = this;
      this.carts = [];
      payload.forEach(function (cart) {
        if (cart.expireAt) cart.expireAt = new Date(cart.expireAt);
        cart.cartState = 'cart';
        _this.carts.push(cart);
      });
    },
    clear: function clear() {
      this.activeCart.items = [];
      this.activeCart.expire = null;
      this.activeCart.total = {
        amount: 0,
        string: 'Жодного товару'
      };
    },
    subscribe: function subscribe(_ref) {
      var slug = _ref.slug,
        price = _ref.price;
      var liqpayStore = useLiqpayStore();
      liqpayStore.setSubscription({
        liqpay: 'subscription',
        slug: slug,
        price: price
      });
      this.$nuxt.$modalityStore.openModal({
        type: 'cart',
        name: 'subscription'
      });
    }
  }
});