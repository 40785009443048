import { useAppStore } from '~/stores/app';

export default function ({ app, $pinia }) {
    const appStore = useAppStore($pinia);

    // Run on initial page loaded
    detectLastPage ();

    // Run after each route change
    app.router.afterEach(detectLastPage);

    function detectLastPage () {
        let state = window.history.state || {};

        // Check if 'isLastCasesPage' is already set in current history state
        if ('isLastCasesPage' in state) { 

            // Case: App reloaded but history state already marked
            if (!appStore.lastCasesPageSet) {
                appStore.setState({key: 'lastCasesPageSet', value: true});
            }

            return;
        }

        // Case: New unmarked history state
        state.isLastCasesPage = appStore.lastCasesPageSet ? false : true;
        
        window.history.replaceState(state, null, window.location.href);

        // Mark that the last cases page state has been set in the store
        if (!appStore.lastCasesPageSet) {
            appStore.setState({key: 'lastCasesPageSet', value: true});
        }
    }
}
