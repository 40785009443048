export var useCourseLandingStore = defineStore({
  id: 'courseLanding',
  state: function state() {
    return {
      course: {},
      reviews: {},
      maps: [],
      plans: [],
      planCourses: [],
      localeValue: 'uk'
    };
  },
  getters: {
    locale: function locale(state) {
      if (state.course && state.course.landingLocale) return state.course.landingLocale;
      return state.localeValue;
    }
  },
  actions: {
    setCourse: function setCourse(course) {
      this.course = course;
    },
    setReviews: function setReviews(reviews) {
      this.reviews = reviews;
    },
    setMaps: function setMaps(maps) {
      this.maps = maps;
    },
    setPlans: function setPlans(plans) {
      this.plans = plans;
    },
    setPlanCourses: function setPlanCourses(planCourses) {
      this.planCourses = planCourses;
    },
    setLocale: function setLocale(locale) {
      this.localeValue = locale;
    }
  }
});