export const useCourses = () => {
    const categories = [
        'graphic',
        'smm',
        'instrumental',
        'ux-ui',
        'enterpreneurship',
        'ai',
        'motion',
        'frontend',
        // 'product-management',
    ];

    const levels = [
        'beginner',
        'intermediate',
        'advanced',
    ];

    const schools = [
        'cp',
        // 'asta-academy',
        'kukurudza',
        'webflow-university',
    ];


    return {
        categories,
        levels,
        schools,
    }
}