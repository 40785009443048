<template>
    <button
        @click="$modalityStore.toggleModal({name, type: 'drawer', noTint})"
        :class="{active: isActive}"
    >
        <slot/>
    </button>
</template>

<script>
export default {
    name: 'PanelButton',

    props: ['name', 'noTint'],

    computed: {
        isActive () {
            return this.$modalityStore.drawer == this.name;
        }
    }
}
</script>

