import "core-js/modules/es.function.name.js";
export var useTabStore = defineStore({
  id: 'tab',
  state: function state() {
    return {
      tabs: {}
    };
  },
  actions: {
    toggle: function toggle(_ref) {
      var scope = _ref.scope,
        name = _ref.name;
      if (this.tabs[scope]) this.tabs[scope] = name;else this.tabs[scope] = name;
    }
  }
});