import Vue from 'vue';
import { useProfileStore } from '~/stores/profile';

Vue.directive('profile', {
    bind: function(el, binding, vnode) {
        const profileStore = useProfileStore()

        el.mouseEnter = function () {

            // Disable for mobile
            if (
                vnode.context.$mq == 'xs' ||
                !binding.value ||
                !binding.value.modelId ||
                !binding.value.modelName
            ) return;

            if (!el.id) el.id = `profile-${new Date().getTime()}`;

            profileStore.open({
                id: el.id,
                value: binding.value
            });
        }

        el.mouseLeave = function () {
            profileStore.close();
        }


        el.addEventListener('mouseenter', el.mouseEnter);
        el.addEventListener('mouseleave', el.mouseLeave);
    },

    unbind: function(el, binding, vnode) {
        el.removeEventListener('mouseenter', el.mouseEnter);
        el.removeEventListener('mouseleave', el.mouseLeave);
    }
})
