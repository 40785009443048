import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { useFeedStore } from '~/storeModules/useFeed.js';
export var useFeedsStore = defineStore({
  id: 'feeds',
  state: function state() {
    return {
      rooms: [],
      feeds: []
    };
  },
  actions: {
    addRoom: function addRoom(roomName) {
      this.rooms.push(roomName);
    },
    addFeed: function addFeed(roomName) {
      if (!this.feeds.includes(roomName)) this.feeds.push(roomName);
    },
    updatePosts: function updatePosts(_ref) {
      var _this = this;
      var ids = _ref.ids,
        update = _ref.update;
      ids.forEach(function (id) {
        _this.feeds.forEach(function (feed) {
          var feedStore = useFeedStore(feed);
          feedStore.updatePost({
            id: id,
            update: update
          });
        });
      });
    },
    newPost: function newPost(_ref2) {
      var roomNames = _ref2.roomNames,
        post = _ref2.post;
      // Check if user accepts post language
      var locales = this.$nuxt.$cookieStore.contentLocale.split('.');
      if (post.locale && !locales.includes(post.locale)) {
        return;
      }
      this.feeds.forEach(function (feed) {
        var feedStore = useFeedStore(feed);
        feedStore.newPost({
          roomNames: roomNames,
          post: post
        });
      });
    },
    getNew: function getNew() {
      this.feeds.forEach(function (feed) {
        var feedStore = useFeedStore(feed);
        feedStore.getNew();
      });
    },
    clear: function clear() {
      this.feeds.forEach(function (feed) {
        var feedStore = useFeedStore(feed);
        feedStore.clear();
      });
    }
  }
});