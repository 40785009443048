import { useAppStore } from '~/stores/app';

export default ({ app, $pinia }) => {
    const appStore = useAppStore($pinia);

    // Set CSRF token for app instance
    app.$axios.get('/csrfToken')
    .then((response) => {
        app.$axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data._csrf;
        appStore.setState({key: 'csrfToken', value: response.data._csrf})
    }, (err) => {
        console.error(err)
    })
}
