import Vue from 'vue'
import { RecycleScroller,  DynamicScrollerItem, DynamicScroller} from 'vue-virtual-scroller'

Vue.component('RecycleScroller',     RecycleScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)
Vue.component('DynamicScroller',     DynamicScroller)

const CasesDynamicScroller = Vue.component('DynamicScroller').extend({
    data () {
        return {
            useCache: true,
            vscrollData: {
                active: true,
                sizes: {},
                validSizes: {},
                keyField: this.keyField,
                simpleArray: false
            }
        }
    },

    computed: {
        itemsWithSize () {
            const result = [];
            const { items, keyField, simpleArray } = this;
            const sizes = this.vscrollData.sizes;

            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const id = simpleArray ? i : item[keyField];
                let size = 0;

                if (item.size && this.useCache) {
                    size = item.size;
                } else {
                    size = sizes[id]
                    if (typeof size === 'undefined' && !this.$_undefinedMap[id]) {
                        size = 0
                    }
                }

                result.push({
                    item,
                    id,
                    size,
                })
            }

            return result
        }
    },

    methods: {
        onScrollerResize () {
            this.clearSizeCache();
            const scroller = this.$refs.scroller
            if (scroller) {
                this.forceUpdate()
            }
            this.$emit('resize')
        },

        clearSizeCache () {
            this.useCache = false;
        }
    }
});

Vue.component('CasesDynamicScroller', CasesDynamicScroller)
delete Vue.options.components['DynamicScroller']
