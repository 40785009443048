export default function ({ isHMR, app, route, req }) {
    if (isHMR) { // ignore if called from hot module replacement
        return;
    }


    // Do for first visit only
    if (req && process.server) {
        if (route.name) {
            let locale = null;

            // check if the locale cookie is set
            if (req.headers.cookie) {
                const cookies = req.headers.cookie.split('; ').map(stringCookie => stringCookie.split('='));
                const cookie = cookies.find(cookie => cookie[0] === 'locale');

                if (cookie) {
                    locale = cookie[1];
                }
            }

            // Sync locale to cookie
            if (locale) return app.i18n.setLocale(locale)


            // res.setHeader('Set-Cookie', [`locale=${app.i18n.locale}`]);
        }
    }
};
