import socketio from "socket.io-client";
import sailsio from 'sails.io.js';
var io = sailsio(socketio);

// try to reconnect if connection is lost
io.sails.reconnection = true;

// Hide logs in production
io.sails.environment = process.env.NODE_ENV == 'production' ? 'production' : 'development';
io.sails.url = process.env.socketUrl; //'https://socket.cases.media';

// Remember this must match te same array in config/sockets.js (server config)
io.sails.transports = ['websocket'];
export default (function (context, inject) {
  inject('socket', io.socket);
  context.$socket = io.socket;
});