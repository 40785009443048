export const useVacancyFields = () => {
    const levels = ['intern', 'junior', 'middle', 'senior', 'lead', 'c-level'];
    const occupations = ['partial', 'fulltime', 'project'];
    const formats = ['office', 'hybrid', 'remote'];

    const displaySalary = ['from-to', 'from', 'to', 'salary', 'none'];
    const currency = ['uah', 'usd', 'eur'];

    return {
        levels,
        occupations,
        formats,
        displaySalary,
        currency
    }
}