import "core-js/modules/es.math.log10.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
export default (function (context, inject) {
  var CDN = process.env.CDN;
  inject('CDN', CDN);
  context.$CDN = CDN;
  function abbreviateNumber() {
    var number = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var SI_SYMBOLS = ['', 'K', 'M', 'B', 'T', 'Q'];
    var tier = Math.log10(Math.abs(number)) / 3 | 0;
    if (tier === 0) return number.toString();
    var suffix = SI_SYMBOLS[tier];
    var scale = Math.pow(10, tier * 3);
    var scaledNumber = number / scale;
    var roundedNumber = Math.round(scaledNumber * 10) / 10;
    return roundedNumber.toString() + suffix;
  }
  inject('abbreviateNumber', abbreviateNumber);
  context.$abbreviateNumber = abbreviateNumber;
});