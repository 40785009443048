import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { useReportCardStore } from './reportCard';
import { useModalityStore } from './modality';
function _courseHasModuleType(course, type) {
  var _course$moduleTypes;
  return course === null || course === void 0 ? void 0 : (_course$moduleTypes = course.moduleTypes) === null || _course$moduleTypes === void 0 ? void 0 : _course$moduleTypes.includes(type);
}
function getPointsByType(course, type) {
  if (!_courseHasModuleType(course, type)) return undefined;
  var $reportCardStore = useReportCardStore();
  var reportCard = $reportCardStore.getCourseCard(course.id);
  if (!(reportCard !== null && reportCard !== void 0 && reportCard.separatedMark)) return undefined;
  if (!(type in reportCard.separatedMark)) return undefined;
  return reportCard.separatedMark[type] || 0;
}
export var useCourseNavbarPanelStore = defineStore({
  id: 'courseNavbar',
  state: function state() {
    return {
      state: 'default',
      tab: 'program',
      course: {},
      modules: [],
      providers: {},
      initialProviders: {},
      messages: {},
      telegramBotEnabled: false,
      telegramBotUsername: 'CasesMediaBot',
      telegramInfo: {}
    };
  },
  getters: {
    states: function states() {
      return ['default', 'points', 'telegram'];
    },
    tabs: function tabs() {
      return ['program', 'archive', 'notifications', 'watch-mode'];
    },
    messageKeys: function messageKeys() {
      return ['course_release', 'course_module_release', 'course_practice', 'course_cooldown'];
    },
    pointsTheory: function pointsTheory(state) {
      return getPointsByType(state.course, 'theory');
    },
    pointsPractice: function pointsPractice(state) {
      return getPointsByType(state.course, 'practice');
    },
    pointsCreativity: function pointsCreativity(state) {
      return getPointsByType(state.course, 'creativity');
    }
  },
  actions: {
    setState: function setState(state) {
      if (this.state == state) return;
      if (!this.states.includes(state)) return;
      this.state = state;
    },
    setTab: function setTab(tab) {
      if (this.tab == tab) return;
      if (!this.tabs.includes(tab)) return;
      this.tab = tab;
    },
    reset: function reset() {
      this.state = 'default';
      this.tab = 'program';
    },
    close: function close() {
      var $modalityStore = useModalityStore();
      $modalityStore.closeModal({
        type: 'drawer'
      });
      this.reset();
    },
    courseHasModuleType: function courseHasModuleType(type) {
      return _courseHasModuleType(this.course, type);
    }
  }
});