import { useTimeStore } from './time';
import { useCookieStore } from './cookie';
export var useSaleStore = defineStore({
  id: 'sale',
  state: function state() {
    return {
      saleStart: new Date('2024-09-05T20:59:59.000Z'),
      saleEnd: new Date('2024-10-05T20:59:59.000Z')
    };
  },
  getters: {
    isSaleActive: function isSaleActive() {
      var time = useTimeStore();
      var cookie = useCookieStore();
      if (!cookie.isUkrainian) return false;
      return this.saleStart <= time.now && time.now <= this.saleEnd;
    }
  }
});