import { useAppStore } from '~/stores/app';

export default ({ app, $pinia }) => {
    const appStore = useAppStore($pinia);

    app.nuxt.defaultTransition.beforeLeave = () => {
        app.i18n.finalizePendingLocaleChange();
        appStore.setState({key: 'langSwitching', value: false})
    }

    // app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    //     if (process.browser) {
    //         if (VueCookie.get('i18n_redirected') !== newLocale) {
    //             VueCookie.set('i18n_redirected', newLocale)
    //         }
    //     }
    // }

    app.i18n.silentTranslationWarn = true;
    app.i18n.silentFallbackWarn = true;

    app.i18n.pluralizationRules = {
        'uk': function (choice, choicesLength) {
            if (choice === 0) return 0;

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) return (!teen && endsWithOne) ? 1 : 2;
            if (!teen && endsWithOne) return 1;
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2;

            return (choicesLength < 4) ? 2 : 3;
        }
    }

    // Optional: wait for locale before scrolling for a smoother transition
    // app.router.options.scrollBehavior = async (to, from, savedPosition) => {
    //   // Make sure the route has changed
    //   if (to.name !== from.name) {
    //     await app.i18n.waitForPendingLocaleChange()
    //   }
    //   return savedPosition || { x: 0, y: 0 }
    // }
}
