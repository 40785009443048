export default function({route, redirect}) {
    const redirects = [
        { from: /^(\/(?:uk|ru|en))?\/creativepractice(\/?.*)$/, to: "$1/learning$2" },

        { from: /^(\/(?:uk|ru|en))?\/learning\/study(\/?.*)$/,    to: '$1/learning/my$2' },              // Remove after 2025.09.15
        { from: /^(\/(?:uk|ru|en))?\/learning\/events$/,          to: '$1/events' },                     // Remove after 2025.09.15
        { from: /^(\/(?:uk|ru|en))?\/learning\/articles(\/?.*)$/, to: '$1/creative.practice/articles' }, // Remove after 2025.09.15
        { from: /^(\/(?:uk|ru|en))?\/learning\/article\/(.*)$/,   to: '$1/article/$2' },                 // Remove after 2025.09.15
        { from: /^(\/(?:uk|ru|en))?\/feed(\/?.*)$/,                to: '$1/$2' },                         // Remove after 2025.09.15
    ]

    const redirectRule = redirects.find((r) => r.from.test(route.path));

    if (redirectRule) {
        return redirect(301, route.path.replace(redirectRule.from, redirectRule.to))
    } 
}