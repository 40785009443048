import { useCookieStore } from '~/stores/cookie';
var id = '3198408';
var cv = null;
export default (function (context, inject) {
  var cookieStore = useCookieStore();
  function init() {
    /* eslint-disable */
    if (typeof window !== 'undefined') {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
        h._hjSettings = {
          hjid: id,
          hjsv: cv
        };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }
  var hotJar = {
    enable: function enable() {
      if (process.env.NODE_ENV !== 'production') {
        return console.log('Debug HotJar: Enabled');
      }
      init();
    },
    disable: function disable() {
      var vanilaCookie = useVanilaCookie();
      vanilaCookie.removeCookiesStartingWith('_hj');

      // let cookies = Cookies.get();
      // const regex = /^_hj/;

      // for (let name in cookies) {
      //     if (regex.test(name)) Cookies.remove(name)
      // }

      if (process.env.NODE_ENV !== 'production') {
        console.log('Debug HotJar: Disabled');
      }
    }
  };

  // Make hotJar globally accessible
  inject('hotJar', hotJar);
  context.$hotJar = hotJar;

  // Check cookie consent and run HotJar if enabled 
  if (cookieStore.getCookieConsent.performance) hotJar.enable();
});