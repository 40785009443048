// Scroll plugins

import Vue from 'vue';


// Scroll lock
// See: https://github.com/Pixeldenker/vue-body-scroll-lock
import VBodyScrollLock from 'v-body-scroll-lock'
Vue.use(VBodyScrollLock)

//Allow :active styles to work in CSS on a page in Mobile Safari:
document.addEventListener("touchstart", function () {}, true);
