import Vue from 'vue';
import { useHintStore } from '~/stores/hint';


let i = 0
Vue.directive('hint', {
    inserted: function(el, binding, vnode) {
        const hintStore = useHintStore()

        el.hint = function () {
            if (!vnode.context.$auth.user) return;
            
            window.removeEventListener('hintsHydrated', el.hint)

            // Check if hint has been closed or in some reasone it has no parent element
            if (
                !binding.value || !binding.value.hint ||
                hintStore.closedHints.includes(binding.value.hint) ||
                !el.parentNode
            ) {
                return
            }

            let clone = el.cloneNode(true);

            el.dataset.hint = 'hide';
            el.classList.add('hint-hide');
            clone.classList.add('hint-pulse');

            if (!clone.id) {
                clone.id = `hint-${new Date().getTime()}-${i}`;
                i++;
            }


            el.parentNode.insertBefore(clone, el);
            el.clone = clone;

            clone.mouseClick = function (e) {
                e.preventDefault();
                e.stopPropagation();

                hintStore.open({
                    hint: binding.value.hint,
                    placement: binding.value.placement,
                    fallbackPlacements: binding.value.fallbackPlacements,
                    clone
                });
            }

            clone.hintDone = function () {
                delete el.dataset.hint;
                el.classList.remove('hint-hide');
                el.click();
                clone.remove();
                window.removeEventListener('hintsUpdated', el.updateHint)
            },

            clone.destroy = function () {
                delete el.dataset.hint;
                el.classList.remove('hint-hide');
                clone.remove();
            }

            clone.addEventListener('mouseenter', clone.mouseClick);
            clone.addEventListener('click', clone.mouseClick);
            window.addEventListener('hintsUpdated', el.updateHint)
        }

        el.updateHint = function (e) {
            if (
                e.detail && e.detail.includes(binding.value.hint) &&
                el.clone
            ) {
                el.classList.remove('hint-hide');
                el.clone.remove();
                window.removeEventListener('hintsUpdated', el.updateHint)
            }
        }

        if (!hintStore.hydrated) {
            window.addEventListener('hintsHydrated', el.hint)
        } else {
            el.hint()
        }
    },

    unbind: function(el, binding, vnode) {
        window.removeEventListener('hintsHydrated', el.hint)
        window.removeEventListener('hintsUpdated', el.updateHint)

        if (el.clone) {
            el.clone.removeEventListener('mouseenter', el.clone.mouseClick);
            el.clone.removeEventListener('click', el.clone.mouseEnter);
            delete el.clone;
        }
    }
})
