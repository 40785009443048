function updateOptionsSrc(html, option, val) {
    if (option == 'autoplay' && val == 1) {
        html = updateOptionsSrc(html, 'muted', val);
        html = updateOptionsSrc(html, 'autopause', 0);

        function replaceAllow (match, start, allow) {
            if (!allow.match('autoplay')) allow = `${allow}; autoplay;`

            return [start, '"', allow, '"'].join('');
        }

        html = html.replace(/(<iframe.*allow=)"([^"]+)"/, replaceAllow);
    }


    function replaceParam (match, start, url) {
        let param = `${option}=${val}`;
        let regex = new RegExp(`${option}=[0,1]`);

        if (url.match(regex)) url = url.replace(regex, param);
        else url = `${url}&${param}`

        return [start, '"', url, '"'].join('');
    }

    return html.replace(/(<iframe.*src=)"([^"]+)"/, replaceParam);
}

export const useEmbedOptionsHtml = (html, module) => {
    // Toggle options
    let options = ['autoplay', 'loop', 'background'];
    options.forEach(option => {
        html = updateOptionsSrc(html, option, module[option])
    });

    return html;
}