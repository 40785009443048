import Vue from 'vue';

const toDouble = function (num) {
    if (num.toString().length < 2) num = '0'+num;
    return num;
}

export default ({app}) => {

    const timeformat = {
        'yyyy': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            let year = date.getFullYear();
            return year;
        },

        'dd.mm.yyyy': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            let day = toDouble(date.getDate());
            let month = toDouble(date.getMonth()+1);
            let year = toDouble(date.getFullYear());
            return `${day}.${month}.${year}`;
        },

        'dd mmmm': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            return app.i18n.d(date, 'dd mmmm');
        },

        'dd mmmm ????': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            let now = new Date();
            let year = toDouble(date.getFullYear());
            if (year == now.getFullYear()) return app.i18n.d(date, 'dd mmmm');
            else return app.i18n.d(date, 'dd mmmm yyyy');
        },

        'dd mmmm yyyy': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            return app.i18n.d(date, 'dd mmmm yyyy');
        },

        'dd.mm.???? time': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            let now = new Date();
            let year = toDouble(date.getFullYear());
            if (year == now.getFullYear()) return app.i18n.d(date, 'dd mmmm time');
            else return app.i18n.d(date, 'dd mmmm yyyy time');
        },

        'dd.mm': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            let day = toDouble(date.getDate());
            let month = toDouble(date.getMonth()+1);
            return `${day}.${month}`;
        },

        'hh:mm': function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            let mins = toDouble(date.getMinutes());
            let hours = toDouble(date.getHours());
            return `${hours}:${mins}`;
        },

        ago: function (date) {

            // just now, min, hour, day(2, 3), dd mmmm, dd mmmm yyyy
            let result = '';

            if (!date) return result;
            if (typeof date == 'string') date = new Date(date);
            if (!(date instanceof Date && !isNaN(date))) return result;

            let now = new Date();
            let dateMidnight = new Date(date);
            let nowMidnight = new Date(now);
            let num;

            const diff = now - date;
            const min = 1000 * 60;
            const hour = min * 60;
            const day = hour * 24;
            const ago = app.i18n.t('time.ago');

            dateMidnight.setHours(0,0,0,0);
            nowMidnight.setHours(0,0,0,0);


            if (diff < min) {
                result = app.i18n.t('time.now');
            } else if (diff < hour) {
                num = Math.floor(diff / min);
                result = app.i18n.tc('time.minutes', num) + ' ' + ago;
            } else if (diff < day) {
                num = Math.floor(diff / hour);

                if (num >= 12 && date.getDate() !== now.getDate()) {
                    result = `${app.i18n.t('time.yesterday')} ${app.i18n.t('time.at')} ${app.i18n.d(date, 'hh mm')}`;
                } else {
                    result = app.i18n.tc('time.hours', num) + ' ' + ago;
                }
            } else if (dateMidnight.getTime() == nowMidnight.setDate(nowMidnight.getDate() - 1)) {
                result = `${app.i18n.t('time.yesterday')} ${app.i18n.t('time.at')} ${app.i18n.d(date, 'hh mm')}`;
            } else {
                result = this['dd mmmm ????'](date);
            }

            return result;
        },

        relativeDate: function (date) {
            if (!date) return '';
            if (typeof date == 'string') date = new Date(date);
            if (!(date instanceof Date && !isNaN(date))) return '';

            const now = new Date();

            // Today
            const todayStart = new Date(now);
            const todayEnd   = new Date(now)

            todayStart.setHours(0, 0, 0, 0);
            todayEnd.setHours(23, 59, 59, 999);

            // Yesterday
            const yesterdayStart = new Date(todayStart);
            const yesterdayEnd   = new Date(todayEnd);

            yesterdayStart.setDate(yesterdayStart.getDate() - 1);
            yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);

            // Tomorrow
            const tomorrowStart = new Date(todayStart);
            const tomorrowEnd   = new Date(todayEnd);

            tomorrowStart.setDate(tomorrowStart.getDate() + 1);
            tomorrowEnd.setDate(tomorrowEnd.getDate() + 1);

            if (date >= yesterdayStart && date <= yesterdayEnd) {
                return app.i18n.t('time.yesterday');
            }

            if (date >= todayStart && date <= todayEnd) {
                return app.i18n.t('time.today');
            }

            if (date >= tomorrowStart && date <= tomorrowEnd) {
                return app.i18n.t('time.tomorrow');
            }

            return app.i18n.t('time.onDate', {
                date: this['dd mmmm ????'](date)
            });
        },

        addMonthes: function (date, monthes) {
            if (!date) date = new Date();
            if (typeof date == 'string') date = new Date(date);

            let month = date.getMonth() + monthes;
            let year  = date.getFullYear();
            let day   = date.getDate();

            // Number of last avalible day at end month
            let lastDay = new Date(year, month + 1, 0);

            // Set end day to max day at end month
            // if current day is unavalible at end month
            if (lastDay.getDate() < day) date.setDate(lastDay.getDate());
            else date.setDate(day);

            date.setMonth(month);

            return date;
        }
    }


    Vue.prototype.$timeformat = timeformat;
}
